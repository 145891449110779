





























































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { User } from '@/models/user';
import { parse, ParseResult, unparse } from 'papaparse';
import {
  RaceParsingErrorResult,
  RaceResult,
  RowParsingError,
  UploadHistory,
  ExportInfo,
  RaceType,
  Series,
} from '@/models';
import EditRace from '@/components/race/EditRace.vue';
import RebuildLeaderboard from '@/components/RebuildLeaderboard.vue';
import { Duration } from 'luxon';
import Race from '@/models/race';
import * as xlsx from 'xlsx';
import { matchTest } from '@/firebase/functions/matchTest';
import MatchTestResponseDto from '@/models/matchTestResponseDto';

@Component({
  components: {
    EditRace,
    RebuildLeaderboard,
  },
})
export default class RaceResults extends Vue {
  series: Series | null = null;
  raceResultsFile: File | null = null;
  raceExport: ExportInfo | null = null;
  uploading = false;
  failedParse: RaceParsingErrorResult = { headerError: false, duplicates: [], rows: [] };

  dialog = false;
  dobMenu = false;
  search = '';
  dialogDelete = false;
  editDialog = false;
  loading = false;

  genders = ['Male', 'Female', 'Non-binary'];
  headers = [
    { text: 'First Name', value: 'firstName' },
    { text: 'Last Name', value: 'lastName' },
    { text: 'Gender', value: 'gender' },
    { text: 'Age', value: 'age' },
    { text: 'Time', value: 'time' },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  raceType: RaceType = { id: '', name: '', isStandardRace: false, distanceInMeters: 0 };
  raceResults: RaceResult[] = [];
  uploadHistory: UploadHistory | null | undefined = null;
  defaultItem: RaceResult = {
    id: '',
    firstName: 'John',
    lastName: 'Smith',
    gender: 'Male',
    age: 25,
    time: '00:00:00',
    meters: 0,
  };

  newItem = Object.assign({}, this.defaultItem);
  editedItem = Object.assign({}, this.defaultItem);
  deleteRaceResultId = '';
  orgId = '';

  testItemId = '';
  testDialog = false;
  matchTestResult?: MatchTestResponseDto;
  matchTestRaceResult?: RaceResult;

  get timeOrMetersHeader() {
    if (!this.raceType) return { text: 'Time', value: 'time' };
    return this.raceType.isStandardRace
      ? { text: 'Time', value: 'time' }
      : { text: 'Meters', value: 'meters' };
  }

  get race(): Race | null {
    return this.$store.getters['races/getRace'](this.$route.params.raceSlug);
  }

  seriesDetails(seriesId: string): Series | null {
    return this.$store.getters['series/seriesDetails'](seriesId);
  }

  created() {
    this.initialize();
  }

  async initialize() {
    this.loading = true;
    if (this.$store.state.organizations.organizations == null) {
      await this.$store.dispatch('organizations/getOrganizations');
    }

    this.orgId = this.$store.getters['organizations/getOrgIdFromSlug'](
      this.$route.params.organizationSlug
    );

    if (this.$store.state.series.seriesList == null) {
      await this.$store.dispatch(
        'series/getSeriesList',
        this.$store.getters['organizations/getOrgIdFromSlug'](this.$route.params.organizationSlug)
      );
    }

    this.series = this.seriesDetails(this.$route.params.seriesSlug);

    await this.$store.dispatch('races/getRaceList', {
      orgId: this.$store.getters['organizations/getOrgIdFromSlug'](
        this.$route.params.organizationSlug
      ),
      seriesId: this.$route.params.seriesSlug,
    });

    await this.$store.dispatch('races/getRaceResults', {
      orgId: this.$store.getters['organizations/getOrgIdFromSlug'](
        this.$route.params.organizationSlug
      ),
      seriesId: this.$route.params.seriesSlug,
      raceId: this.$route.params.raceSlug,
    });

    // Get race type
    this.raceType = this.$store.state.races.raceList.find(
      (r: Race) => r.id === this.$route.params.raceSlug
    ).raceType;

    // set header based on type
    const timeHeader = this.headers.find(h => h.text === 'Time');
    if (!this.raceType.isStandardRace && timeHeader) {
      timeHeader.text = 'Meters';
      timeHeader.value = 'meters';
    }

    this.raceResults =
      (await this.$store.getters['races/raceResults'](this.$route.params.raceSlug)) || [];

    if (this.raceResults.length) {
      this.raceExport = {
        url: URL.createObjectURL(
          new Blob(
            [
              unparse(this.raceResults, {
                columns: ['id', 'firstName', 'lastName', 'club', 'gender', 'age', 'time', 'meters'],
              }),
            ],
            { type: 'text/csv' }
          )
        ),
        filename: `${this.$route.params.seriesSlug}-${
          this.$route.params.raceSlug
        }-${new Date().toISOString().substr(0, 10)}`,
      };
    }

    this.uploadHistory =
      this.$store.getters['races/raceUploadHistory'](this.$route.params.raceSlug) || null;

    this.loading = false;
  }

  editItem(item: RaceResult) {
    if (this.raceResults) {
      this.editedItem = Object.assign({}, item);
      this.editDialog = true;
    }
  }

  async runTests(item: RaceResult) {
    this.testItemId = item.id;
    this.loading = true;

    try {
      const resp = await matchTest({
        organizationId: this.orgId,
        seriesId: this.$route.params.seriesSlug,
        raceId: this.$route.params.raceSlug,
        raceResult: item,
      });

      const matchTestResult: MatchTestResponseDto = resp.data;
      this.matchTestResult = matchTestResult;
      this.matchTestRaceResult = item;
      this.testDialog = true;
    } catch (error) {
      this.$store.dispatch('errorModal/showDialog', {
        title: 'Error troubleshooting matches',
        message: (error as Error).message,
      });
    } finally {
      this.loading = false;
    }
  }

  toAge(dateOfBirth: string): number {
    return Math.floor(
      Math.abs(new Date(this.race!.date).getTime() - new Date(dateOfBirth).getTime()) /
        (1000 * 60 * 60 * 24 * 365.25)
    );
  }

  deleteItem(item: RaceResult) {
    this.deleteRaceResultId = item.id;
    this.dialogDelete = true;
  }

  async deleteItemConfirm() {
    const raceId = this.$route.params.raceSlug;
    await this.$store.dispatch('races/deleteRaceResult', {
      orgId: this.$store.getters['organizations/getOrgIdFromSlug'](
        this.$route.params.organizationSlug
      ),
      seriesId: this.$route.params.seriesSlug,
      raceId: raceId,
      raceResultId: this.deleteRaceResultId,
    });
    this.closeDelete();
  }

  async editItemConfirm() {
    const raceId = this.$route.params.raceSlug;
    await this.$store.dispatch('races/updateRaceResult', {
      orgId: this.$store.getters['organizations/getOrgIdFromSlug'](
        this.$route.params.organizationSlug
      ),
      seriesId: this.$route.params.seriesSlug,
      raceId: raceId,
      raceResult: this.editedItem,
    });
    this.closeEdit();
  }

  close() {
    this.dialog = false;
    this.$nextTick(() => {
      this.newItem = Object.assign({}, this.defaultItem);
    });

    this.initialize();
  }

  closeDelete() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.deleteRaceResultId = '';
    });

    this.initialize();
  }

  closeEdit() {
    this.editDialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
    });

    this.initialize();
  }

  closeTestDialog() {
    this.testDialog = false;
  }

  async save() {
    const raceId = this.$route.params.raceSlug;
    if (this.raceType.isStandardRace) {
      delete this.newItem['meters'];
    } else {
      delete this.newItem['time'];
    }
    await this.$store.dispatch('races/addRaceResult', {
      orgId: this.$store.getters['organizations/getOrgIdFromSlug'](
        this.$route.params.organizationSlug
      ),
      seriesId: this.$route.params.seriesSlug,
      raceId: raceId,
      raceResult: this.newItem,
    });
    this.close();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  filterText(value: string, search: string | null, _item: never) {
    return (
      value != null &&
      search != null &&
      typeof value === 'string' &&
      value
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1
    );
  }

  get user(): User | null {
    return this.$store.state.auth.user;
  }

  performRaceDataValidation(results: ParseResult<RaceResult>): RaceParsingErrorResult {
    const parsingResult: RaceParsingErrorResult = { headerError: false, duplicates: [], rows: [] };
    const track = new Map<string, number[]>();

    //Header checking
    if (results) {
      const correctHeaders = [
        'firstName',
        'lastName',
        'age',
        'gender',
        this.raceType.isStandardRace ? 'time' : 'meters',
      ];

      const parsedOutHeaders = results.meta.fields;
      if (parsedOutHeaders) {
        const cleanedUpParsedHeaders = parsedOutHeaders.map(header => header.trim());
        const missingHeaders = correctHeaders.filter(
          item => cleanedUpParsedHeaders.indexOf(item) < 0
        );
        if (missingHeaders.length > 0) {
          parsingResult.headerError = true;
          parsingResult.rows.push({
            index: 0,
            errors: [missingHeaders.join(', ')],
          });
          return parsingResult;
        }
      } else {
        parsingResult.headerError = true;
        parsingResult.rows.push({
          index: 0,
          errors: [correctHeaders.join(', ')],
        });
        return parsingResult;
      }
    }

    for (let index = 0; index < results.data.length; index++) {
      const { id, firstName, lastName, age, gender, time, meters } = results.data[index];
      const row: RowParsingError = { index: index + 1, errors: [] };

      // field data validation
      const genders = this.genders.map(gender => gender.toUpperCase());
      if (typeof firstName != 'string' || !firstName.length) {
        row.errors.push(`Invalid First Name (must not be a number)`);
      }
      if (typeof lastName != 'string' || !lastName.length) {
        row.errors.push(`Invalid Last Name (must not be a number)`);
      }
      if (typeof gender != 'string' || !genders.includes(gender.toUpperCase())) {
        row.errors.push(`Invalid Gender (must be Male or Female)`);
      }
      if (typeof age != 'number' || Math.sign(age) <= 0) {
        row.errors.push(`Invalid Age (must be greater than 0)`);
      }
      if (this.raceType.isStandardRace) {
        if (!time || !Duration.fromISOTime(time).isValid) {
          row.errors.push(`Invalid Race Time (must be hh:mm:ss)`);
        }
      } else {
        if (!meters || Math.sign(meters) <= 0) {
          row.errors.push(`Invalid meters score (must greater than 0)`);
        }
      }

      if (
        typeof firstName == 'string' &&
        typeof lastName == 'string' &&
        typeof gender == 'string' &&
        typeof age == 'number'
      ) {
        const key = `${firstName.trim()}-${lastName.trim()}-${gender.toUpperCase()}-${age}`;

        //duplicate checking
        if (track.has(key)) {
          track.get(key)?.push(index + 2);
        } else {
          track.set(key, [index + 2]);
        }
      }

      //adding the rows that have errors present
      if (row.errors.length) {
        parsingResult.rows.push(row);
      }

      const raceResult = { id, firstName, lastName, age, gender, time, meters };
      results.data[index] = raceResult;
    }

    parsingResult.duplicates = Array.from(track.values()).filter(value => value.length > 1);
    return parsingResult;
  }

  submit() {
    try {
      this.uploading = true;
      if (!this.raceResultsFile) {
        throw Error('No file');
      }

      parse(this.raceResultsFile, {
        dynamicTyping: true,
        header: true,
        complete: async (results: ParseResult<RaceResult>, raceResultsFile: File) => {
          try {
            const regex = new RegExp('.*.xlsx');
            if (regex.test(raceResultsFile.name)) {
              let workbook: xlsx.WorkBook;
              await raceResultsFile.arrayBuffer().then(value => {
                workbook = xlsx.read(value);
                results.data = xlsx.utils.sheet_to_json<RaceResult>(
                  workbook.Sheets[workbook.SheetNames[0]]
                );
                results.errors = [];
                results.meta.fields = Object.keys(results.data[0]);
              });
            }

            this.failedParse = this.performRaceDataValidation(results);

            if (!this.failedParse.rows.length && !this.failedParse.duplicates.length) {
              const raceId = this.$route.params.raceSlug;

              await this.$store.dispatch('races/uploadRaceResults', {
                organizationId: this.orgId,
                seriesId: this.$route.params.seriesSlug,
                raceId: raceId,
                raceResults: results.data as RaceResult[],
              });

              const uploadHistory: UploadHistory = {
                filename: raceResultsFile.name,
                timestamp: new Date().toISOString().substr(0, 10),
              };

              await this.$store.dispatch('races/updateUploadHistory', {
                organizationId: this.orgId,
                seriesId: this.$route.params.seriesSlug,
                raceId: raceId,
                uploadHistory: uploadHistory,
              });

              this.raceResultsFile = null;
              this.initialize();
            }
          } catch (error) {
            this.$store.dispatch('errorModal/showDialog', {
              title: 'Error Uploading Race Results',
              message: (error as Error).message,
            });
          } finally {
            this.uploading = false;
          }
        },
      });
    } catch (error) {
      console.error(error);
      this.uploading = false;
    }
  }
}
