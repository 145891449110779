































































































import Race from '@/models/race';
import { RaceType } from '@/models/raceType';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DateTime } from 'luxon';

@Component
export default class EditRace extends Vue {
  @Prop({ required: true }) readonly race!: Race;
  @Prop({ required: true }) readonly orgId!: string;
  @Prop({ required: true }) readonly seriesId!: string;

  showSave = false;
  dobMenu = false;
  newRaceName = this.race.name || '';
  newRaceType = this.race.raceType.id || '';
  raceTypes: { text: string; value: string }[] = [];
  newRaceDate = this.race.date || '';
  loading = false;

  async created() {
    if (this.$store.state.raceTypes.raceTypes == null) {
      await this.$store.dispatch('raceTypes/getRaceTypes');
    }
    this.raceTypes = this.$store.state.raceTypes.raceTypes.map((raceType: RaceType) => ({
      text: raceType.name,
      value: raceType.id,
    }));
  }

  resetMenu() {
    this.newRaceName = '';
    this.newRaceDate = '';
    this.newRaceType = '';
  }

  async save() {
    this.loading = true;
    const newRace: Partial<Race> = {
      id: this.race.id,
      name: this.newRaceName,
      raceType: this.$store.state.raceTypes.raceTypes.find(
        (rt: RaceType) => rt.id === this.newRaceType
      ),
      date: this.newRaceDate,
    };

    await this.$store.dispatch('races/updateRace', {
      orgId: this.$store.getters['organizations/getOrgIdFromSlug'](
        this.$route.params.organizationSlug
      ),
      seriesId: this.$route.params.seriesSlug,
      race: newRace,
    });
    this.loading = false;
  }

  raceNameValid(name: string): boolean {
    const valid = name !== null && name !== undefined && name.length !== 0;
    this.showSave = valid;
    return valid;
  }

  raceDateValid(date: string): boolean {
    const valid = date !== null && date !== undefined && DateTime.fromISO(date).isValid;
    this.showSave = valid;
    return valid;
  }
}
